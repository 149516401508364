export const dateFormat = {
  methods: {
    formatDate (value) {
      if (value) {
        const [year, month, day] = value.split('-')
        return [month, day, year].join('-')
      }
      return ''
    },

    formatDateTime(value) {
      if (value) {
        const [date, time] = value.split('T')
        const [hms] = time.split('.')
        return [date, hms].join(' ')
      }
    }
  }
}
