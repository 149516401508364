<template>
    <v-dialog :value="value" persistent width='700px'>
        <v-card flat>
            <v-card-title>
                New Billing Period
            </v-card-title>
            <v-card-text class="pb-0">
               <v-form ref="form" lazy-validation>
                 <v-container class="mx-0 px-0 mt-2">
                   <v-row>
                     <v-col>
                      <v-select
                        v-model="calendarEvent.responsible_party_id"
                        label="Responsible Party*"
                        :items="validParties"
                        :menu-props="{ offsetY: true }"
                        item-text="name"
                        item-value="id"
                        :rules="[v => !!v || 'Responsible party is required']"
                        outlined
                        hide-details="auto"
                        dense>
                      </v-select>
                     </v-col>
                   </v-row>
                   <v-row>
                   <v-col>
                  <v-text-field
                    v-model="calendarEvent.period_name"
                    label="Name*"
                    required
                    :rules="[v => !!v || 'Name is required']"
                    hide-details="auto"
                    outlined
                    dense>
                  </v-text-field>
                  </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                      v-model="calendarEvent.fiscal_year"
                      label="Fiscal Year*"
                      required
                      :rules="[v => !!v || 'Fiscal Year Required']"
                      hide-details="auto"
                      outlined
                      dense>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                      v-model="calendarEvent.start_day_of_week"
                      label="Start Day of Week*"
                      required
                      :items="weekdays"
                      item-value="value"
                      item-text="name"
                      :rules="[v => !!v || 'Start Day is required']">
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu
                      close-on-content-click
                      transition="scale-transition"
                      offset-y
                      nudge-top="25"
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-on="on"
                          :attrs="attrs"
                          :value="calendarEvent.start_date"
                          label="Start Date"
                          required
                          :rules="!calendarEvent.start_date ? [v => !!v || 'Start Date is required'] : []"
                          prepend-inner-icon="mdi-calendar"
                          validate-on-blur
                          readonly
                          outlined
                          dense>
                        </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="calendarEvent.start_date"
                          no-title>
                        </v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                      close-on-content-click
                      transition="scale-transition"
                      offset-y
                      nudge-top="25"
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-on="on"
                          :attrs="attrs"
                          :value="calendarEvent.end_date"
                          label="End Date"
                          required
                          :rules="!calendarEvent.end_date ? [v => !!v || 'End Date is required'] : []"
                          prepend-inner-icon="mdi-calendar"
                          validate-on-blur
                          readonly
                          outlined
                          dense>
                        </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="calendarEvent.end_date"
                          no-title>
                        </v-date-picker>
                    </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-menu
                      close-on-content-click
                      transition="scale-transition"
                      offset-y
                      nudge-top="25"
                      max-width="290px"
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-on="on"
                          :attrs="attrs"
                          :value="calendarEvent.finalize_date"
                          label="Finalize Date"
                          required
                          :rules="!calendarEvent.finalize_date ? [v => !!v || 'Finalize Date is required'] : []"
                          prepend-inner-icon="mdi-calendar"
                          validate-on-blur
                          readonly
                          outlined
                          dense>
                        </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="calendarEvent.finalize_date"
                          no-title>
                        </v-date-picker>
                    </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                      v-model="calendarEvent.period_interval"
                      label="Period Interval*"
                      :items="intervals"
                      item-text="name"
                      item-value="value"
                      :rules="!calendarEvent.period_interval ? [v => !!v || 'Period Interval Required'] : []">
                      </v-select>

                    </v-col>
                  </v-row>
                 </v-container>
               </v-form>
            </v-card-text>
            <v-card-actions>
            <v-row dense>
              <v-col cols="auto" class="ml-auto">
                <v-btn text
                  color="grey darken-2"
                  @click="$emit('update:value', false)">
                  Cancel
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="success"
                  @click="createEvent" 
                  :loading="loading"
                  :disabled="loading">
                  Add
                </v-btn>
              </v-col>
            </v-row>
    </v-card-actions>


        </v-card>

    </v-dialog>

</template>



<script>
import BillingCalendar from '@/axios/billing-calendar'
import { dateFormat } from '@/mixins/date-format'
import { validParties } from '@/mixins/valid-parties'
import { displayAlert } from '@/mixins/displayAlert'

export default ({
    name: 'AddEvent',

    props: ['value'],

    data () {
    return {
      loading: false,
      calendarEvent: {
        responsible_party_id: null,
        period_name: null,
        fiscal_year: null,
        period_interval: null,
        start_day_of_week: null,
        start_date: null,
        end_date: null,
        finalize_date: null,
        menu1: false,
        menu2: false,
        menu3: false,
      },
      weekdays: [{'name': 'Monday', 'value': 'monday'}, {'name': 'Tuesday', 'value': 'tuesday'}, {'name': 'Wednesday', 'value': 'wednesday'}, {'name': 'Thursday', 'value': 'thursday'}, 
      {'name': 'Friday', 'value': 'friday'}, {'name': 'Saturday', 'value': 'saturday'}, {'name': 'Sunday', 'value': 'sunday'}],
      intervals: [{'name': 'Weekly', 'value': 'weekly'}, {'name': 'Monthly', 'value': 'monthly'}, {'name': 'Bimonthly', 'value': 'bimonthly'}, {'name': 'Quarterly', 'value': 'quarterly'},
            {'name': 'Annually', 'value': 'annually'}, {'name': 'Biannually', 
            'value': 'biannually'}],
      validParties: [{'name': 'Associated Wholesale Grocers', 'id': '49b5f50f-6bf8-4315-9366-5d993572277d'}],
    }
  },

  mixins: [displayAlert, validParties, dateFormat],

  methods: {
    async createEvent () {
      this.loading= true
      try {
      const res = await BillingCalendar.addEvent(this.calendarEvent)
        if (res?.status === 201) {
          this.emitAlert(true, 'success', 'Successfully Added New Billing Period')
          this.$emit('close', true)
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
        this.$emit('update:value', false)
    }
  }
},
})
</script>
