import PartyRelationship from '@/axios/party-relationship'

export const validParties = {
  computed: {
    userData () {
      return this.$auth.user
    },
    userGroups () {
      return this.$auth.userGroups || []
    },
    isAdmin () {
      return this.userGroups.some(group => group.includes('admin')) || false
    },
    tenant (){
      return this.$auth.tenant
    }
  },

  methods: {
    filterByTenant (parties) {
      return parties.filter(p => p.tenant === this.tenant)
    },
    async getValidParties (parties) {
      try {
        const { email } = this.userData;
        // bypass check if user is admin/iPro
        if (this.isAdmin === true) {
          return this.filterByTenant(parties)
        }
        const results = await Promise.allSettled(parties.map(async party => {
          const { id } = party;
          let party1 = {
            party_1_type: "USER",
            party_1_email: email
          }
          let party2 = { 
            party_2_id: id 
          }
          return await PartyRelationship.getMatch(party1, party2)
        }))

        const validParties = parties.flatMap((party, index) => {
          const result = results[index]
          if (result.status === 'fulfilled' && result.value.data.related === true) {
            return party
          }
          return []
        })
        return this.filterByTenant(validParties)
      } catch (err) {
        console.log(err)
      }
    }
  }
}